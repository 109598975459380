import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Box, Button, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import Grid from "@mui/material/Grid";
import FormElement from "../UI/Form/FormElement/FormElement";

const useStyles = makeStyles()(() => ({
  orderBlock: {
    padding: "30px",
    background: "#ffffff",
    boxShadow: "20px 20px 80px rgba(0, 0, 0, 0.4)",
    borderRadius: "5px",
    border: "solid 2px #eee",
    textAlign: "center"
  },
  title: {
    fontSize: "32px",
    marginBottom: "20px"
  }
}));

const Order = ({totalPrice, onSendOrder}) => {
  const {classes} = useStyles();
  const loading = useSelector(state => state.orders.loading)
  const user = useSelector(state => state.users.user)
  const [userData, setUserData] = useState({
    name: '',
    phone: ''
  })

  useEffect(() => {
    if (user) {
      setUserData({
        name: user.name,
        phone: ""
      })
    }
  }, [user])

  const inputChangeHandler = (e) => {
    const {name, value} = e.target
    if (value.length > 18) {
      return
    }
    setUserData(prev => ({...prev, [name]: value}))
  }

  const formatPhoneNumber = (inputText) => {
    const cleanedInput = inputText.replace(/\D/g, '');

    if (cleanedInput === '996' || cleanedInput === '99') {
      return '+996'
    } else if (cleanedInput.length <= 3) {
      return `+996 (${cleanedInput}`;
    } else if (cleanedInput.length <= 6) {
      return `+996 (${cleanedInput.slice(3, 6)}`;
    } else if (cleanedInput.length <= 9) {
      return `+996 (${cleanedInput.slice(3, 6)}) ${cleanedInput.slice(6)}`;
    } else if (cleanedInput.length <= 12) {
      return `+996 (${cleanedInput.slice(3, 6)}) ${cleanedInput.slice(6, 9)} ${cleanedInput.slice(9)}`;
    } else {
      return `+996 (${cleanedInput.slice(3, 6)}) ${cleanedInput.slice(6, 9)} ${cleanedInput.slice(9, 12)}`;
    }
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target
    const formattedPhoneNumber = formatPhoneNumber(value);

    setUserData(prev => ({...prev, [name]: formattedPhoneNumber}))
  };

  const getDataOrder = (e) => {
    e.preventDefault()

    onSendOrder({name: userData.name, phone: userData.phone, totalPrice})

    setUserData({
      name: '',
      phone: ''
    })
  }

  return (
    <Box component="form" className={classes.orderBlock} onSubmit={getDataOrder} sx={{mt: 1}}>
      <Grid container spacing={2}>
        <FormElement onChange={inputChangeHandler}
                     value={userData.name}
                     name="name"
                     label="Ваше Имя"
                     required
                     type="text"
        />
        <FormElement onChange={handleInputChange}
                     value={userData.phone}
                     name="phone"
                     label="Ваш номер телефона для связи"
                     required
                     type="tel"
        />
      </Grid>
      <Typography className={classes.title}>Стоимость заказа: {totalPrice} с</Typography>
      <Box style={{textAlign: "center"}}>
        <Button type="submit" variant="outlined" disabled={loading}>Подтвердить заказ</Button>
      </Box>
    </Box>
  );
};

export default Order;