import React from 'react';
import {Link} from "react-router-dom";
import {Button} from "@mui/material";

const Anonymous = ({link}) => {
  return (
    <>
      <Button className={link} component={Link} to="/login" color="inherit">
        Вход
      </Button>
    </>
  );
};

export default Anonymous;