import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia, IconButton,
  Paper,
  Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import {fetchProductRequest} from "../../store/actions/productsActions";
import imageNotAvailable from "../../assets/image-not-available.jpg";
import {apiUrl} from "../../config";

const Product = ({match}) => {
  const dispatch = useDispatch();
  const product = useSelector(state => state.products.product);
  const user = useSelector(state => state.users.user)
  let cardImage = imageNotAvailable

  useEffect(() => {
    dispatch(fetchProductRequest(match.params.id));
  }, [dispatch, match.params.id]);

  if (product?.image) {
    cardImage = apiUrl + '/' + product.image
  }

  return (
    product &&
      <Paper elevation={3} square sx={{padding: "15px", maxWidth: "960px", margin: "0 auto"}}>
        {user?.role === 'admin' ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              maxWidth: "600px",
              margin: "0 auto"
            }}
          >
            <Typography
              fontSize="36px"
              fontFamily="Life Savers"
            >
              Общая информация продукта
            </Typography>
            <IconButton component={Link} to={'/add_product?id=' + match.params.id}>
              <EditIcon/>
            </IconButton>
          </Box>
        ) : (
          <Typography
            fontSize="36px"
            fontFamily="Life Savers"
            textAlign="center"
          >
            Общая информация продукта
          </Typography>
        )}
        <Card sx={{
          height: '100%',
          boxShadow: "6",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          maxWidth: "600px",
          margin: "40px auto"
        }}>
          <CardHeader title={product.title}/>
          <CardMedia
            title={product.title}
            image={cardImage}
            sx={{paddingTop: '65%', height: 0}}
          />
          <CardContent>
            <Typography paddingY="10px">
              {product.description}
            </Typography>
            <strong>
              Цена: {product.price} KGS
            </strong>
          </CardContent>
        </Card>
      </Paper>
  );
};

export default Product;