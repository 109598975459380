import React, {useEffect, useMemo} from 'react';
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Typography} from "@mui/material";
import ProductForm from "../../components/ProductForm/ProductForm";
import {createProductRequest, fetchProductRequest, updateProductRequest} from "../../store/actions/productsActions";

const AddProduct = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.users.user);
  const product = useSelector(state => state.products.product);
  const error = useSelector(state => state.products.error)

  const history = useHistory();

  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    if (user && user.role !== 'admin') {
      return <Redirect to="/"/>
    }
  }, [user])

  useEffect(() => {
    const paramValue = searchParams.get('id')
    if (paramValue) {
      if (product && product._id !== paramValue) {
        dispatch(fetchProductRequest(paramValue));
      } else if (!product) {
        dispatch(fetchProductRequest(paramValue));
      }
    }
  }, [dispatch, searchParams, product]);

  const onProductFormSubmit = async productData => {
    const id = searchParams.get('id')
    if (id) {
      await dispatch(updateProductRequest({productData, token: user.token, _id: id}))
      history.push(`/products/${id}`)
    } else {
      dispatch(createProductRequest({productData, token: user.token}));
    }
  };

  return user && (
    <>
      <Typography
        textAlign="center"
        marginBottom="20px"
        variant="h4"
      >
        {searchParams.get('id') ? 'Редактировать товар' : 'Добавить товар'}
      </Typography>
      <ProductForm
        onSubmit={onProductFormSubmit}
        error={error}
        product={searchParams.get('id') ? product : null}
      />
    </>
  );
};

export default AddProduct;