import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import Swal from "sweetalert2";

import {fetchProductsRequest} from "../../store/actions/productsActions";
import ProductItem from "../../components/ProductItem/ProductItem";
import discounts from '../../assets/discounts.webp';

const Main = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.products.loading);
  const products = useSelector(state => state.products.products);
  const basket = useSelector(state => state.users.basket)

  useEffect(() => {
    dispatch(fetchProductsRequest());
  }, [dispatch]);

  const checkDiscounts = () => {
    Swal.fire({
      toast: false,
      icon: 'info',
      title: `Скидки`,
      html: '<p><b>10+ шт: </b>-3%</p><p><b>20+ шт: </b>-5%</p><p><b>50+ шт: </b>-10%</p>',
      position: "center",
      showConfirmButton: true,
      confirmButtonText: "Закрыть",
      confirmButtonColor: 'rgba(66,227,116,0.7)',
      iconColor: "rgba(66,227,116,0.7)",
      color: "black",
      background: "#FCFCFC",
    })
  }

  return loading ? <Box sx={{textAlign: 'center'}}><CircularProgress/></Box> : (
    <Grid container direction="column" spacing={2}>
      {products?.length !== 0 ? (
        <>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="h3"
                fontFamily="Baltica"
                sx={{display: 'flex', justifyContent: 'space-between', fontSize: {xs: '32px', md: '44px'}, marginBottom: {xs: '20px', md: '32px'}}}
              >
                <span>Товары</span>
                <Typography
                  onClick={checkDiscounts}
                  variant="span"
                  sx={{
                    cursor: 'pointer',
                    display: 'inline-block',
                    width: {xs: '40px', md: '50px'},
                    height: {xs: '40px', md: '50px'},
                    marginRight: '10%',
                  }}
                >
                  <img style={{width: '100%'}} src={discounts} alt="discounts"/>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={3}>
            {products.map(product => {
              let disabled = false

              if (basket?.length) {
                basket.forEach(item => {
                  if (item._id === product._id) disabled = true
                })
              }

              return (
                <ProductItem
                  key={product._id}
                  _id={product._id}
                  title={product.title}
                  price={product.price}
                  image={product.image}
                  stock={product.stock}
                  disabled={disabled}
                />
              )
            })}
          </Grid>
        </>
      ) : <Typography variant="h3"
                      margin="150px 0"
                      textAlign="center"
                      color="red"
                      sx={{fontSize: {xs: "30px", sm: "48px"}}}
      >Временно нет товаров
      </Typography>
      }
    </Grid>
  );
};

export default Main;